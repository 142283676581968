.contentGridTemplate {
  --padding-for-large-desktop: 0 3.125rem;
  --padding-for-desktop: 0 3.4375rem;
  --padding-for-tablet: 0 1.5625rem;
  --padding-for-small: 0 0.8125rem;
  --padding-for-tv: 0 4rem;
  --margin-for-large-desktop: 0.3125rem;
  --margin-for-tablet: 0.3125rem;
  --margin-for-small: 0.1875rem;
  --margin-for-tv: 1.25rem 1.625rem;

  &--detailV5 {
    --padding-for-small: 0 1rem;
    --padding-for-tablet: 0 2rem;
    --padding-for-desktop: 0 4rem;
    --padding-for-large-desktop: 0 4rem;

    @media (--mq-below-height-threshold) {
      --padding-for-desktop: 0 2rem;
      --padding-for-large-desktop: 0 2rem;
    }
  }

  &--hasTopSpace {
    margin-top: 1.9375rem;
  }

  @mixin paddingPages var(--padding-for-large-desktop),
    var(--padding-for-desktop), var(--padding-for-tablet),
    var(--padding-for-small);

  @mixin tvDevice {
    @mixin paddingPages var(--padding-for-tv), var(--padding-for-tv),
      var(--padding-for-tv), var(--padding-for-tv);
  }
  position: relative;
  font-size: 0; /* fix space between inline--block elements */
  color: var(--color_primary);

  &__heading {
    margin-bottom: 0.5rem;

    @media (--mq-from-large-desktop) {
      margin-bottom: 1rem;
    }

    @mixin tvDevice {
      margin: 0 1.625rem 2rem 1.625rem;
    }
  }
}

.contentGrid {
  --padding-for-large-desktop: 0 3.125rem;
  --padding-for-desktop: 0 3.4375rem;
  --padding-for-tablet: 0 1.5625rem;
  --padding-for-small: 0 0.8125rem;
  --padding-for-tv: 0 4rem;
  --margin-for-large-desktop: 0.3125rem;
  --margin-for-tablet: 0.3125rem;
  --margin-for-small: 0.1875rem;
  --margin-for-tv: 1.25rem 1.625rem;

  /* to keep align title & grid */
  &__header {
    margin: 0 var(--margin-for-small);

    @media (--mq-from-laptop) {
      margin: 0 var(--margin-for-tablet);
    }

    @media (--mq-from-large-desktop) {
      margin: 0 var(--margin-for-large-desktop);
    }

    @mixin tvDevice {
      margin: 0 1.625rem;
      color: var(--color_white-80) !important;
    }

    &--detailV5 {
      margin: 0;

      @media (--mq-from-laptop) {
        margin: 0;
      }
      @media (--mq-from-large-desktop) {
        margin: 0;
      }
      @mixin tvDevice {
        margin: 0;
      }
    }
  }

  &__gridItem {
    @mixin responsiveGrid contentGrid;
    @mixin responsiveGrid contentGridTv, true;

    position: relative;
    display: inline-block;
    margin-bottom: 0.9375rem;
    vertical-align: top;

    &--detailV5 {
      @mixin responsiveGrid contentGridDetailV5;
    }

    &--creativeMedia {
      @mixin responsiveGrid creativeMedia;
    }

    @media (--mq-from-laptop) {
      margin-bottom: 0;
    }

    > :first-child {
      @mixin marginGridItems var(--margin-for-large-desktop),
        var(--margin-for-tablet), var(--margin-for-small);
      display: block;
      @mixin tvDevice {
        @mixin marginGridItems var(--margin-for-tv), var(--margin-for-tv),
          var(--margin-for-tv);
      }
    }
  }

  &__error {
    @mixin tvDevice {
      margin: 0 1.625rem;
    }
  }
}

.spinner__content {
  position: relative;
  height: 160px;
}
