/**
  * Add padding for different contentRow
  *
  * $largeDesktop - padding for largeDesktop screen
  * $desktop - padding for desktop screen
  * $tablet - padding for tablet screen
  * $small - padding for small screen
  */
@define-mixin paddingPages $largeDesktop, $desktop, $tablet, $small {
  padding: $small;

  @media (--mq-from-tablet) {
    & {
      padding: $tablet;
    }

    @media (--mq-from-desktop) {
      & {
        padding: $desktop;
      }
    }

    @media (--mq-from-large-desktop) {
      & {
        padding: $largeDesktop;
      }
    }
  }

  [data-viewport~='resize']& {
    padding: $largeDesktop;

    @media (--mq-from-tablet) {
      & {
        padding: $largeDesktop;
      }

      @media (--mq-from-desktop) {
        & {
          padding: $largeDesktop;
        }
      }

      @media (--mq-from-large-desktop) {
        & {
          padding: $largeDesktop;
        }
      }
    }
  }
}
