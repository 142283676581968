.cardRemoveButton {
  @mixin size 1.3125rem;

  position: absolute;
  top: 0;
  right: 0;
  left: initial;

  /* WARNING : if you change this z-index,
    it causes trouble if you do a search after a click on My playlist (in user menu)
    => case of remove button wrongly displayed
    This z-index must be lower than in 'search.css'
  */
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem;
  margin-left: auto;
  background-color: var(--color_cardRemoveButton-bg);
  border-radius: 50%;
  opacity: 0.8;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &--hasSticker {
    position: relative;
    margin: 0 0 0.5rem 0.5rem;
  }

  svg {
    height: 1.1875rem;
    fill: var(--color_white);
  }
}
