.playBackIcon {
  @mixin size 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: ease-in-out 200ms;
  &:hover {
    opacity: 1;
  }

  &--isDetailV5 {
    opacity: 1;
  }

  &__background {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color_black-50);
    border-radius: 18.75rem;
    @mixin size 3.125rem, 3.125rem;

    svg {
      @mixin size 1.375rem, 1.375rem;
      pointer-events: none;
      outline: 0;
      filter: drop-shadow(0 0.125rem 0.375rem rgba(28, 28, 28, 0.35));
      fill: var(--color_white);
    }

    @mixin tvDevice {
      @mixin size 7.625rem, 7.625rem;
      svg {
        @mixin size 3.8125rem, 3.375rem;
      }
    }
  }
}
