.mediaFooterWrapper {
  overflow: hidden;

  &--twolines {
    height: 2.5rem;

    @mixin tvDevice {
      height: 4.5rem;
    }
  }

  &--threelines {
    height: 3.75rem;

    @mixin tvDevice {
      height: 6.625rem;
    }
  }
}
