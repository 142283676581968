/**
  * Add margin for different contentRow and contentGrid
  *
  * $wide - padding for wide screen
  * $desktop - padding for desktop screen
  * $small - padding for small screen
  */
@define-mixin marginGridItems $desktop, $tablet, $small {
  margin: $small;

  @media (--mq-from-tablet) {
    & {
      margin: $tablet;
    }

    @media (--mq-from-large-desktop) {
      & {
        margin: $desktop;
      }
    }
  }

  [data-viewport~='resize'] & {
    margin: $desktop;

    @media (--mq-from-tablet) {
      & {
        margin: $desktop;
      }

      @media (--mq-from-large-desktop) {
        & {
          margin: $desktop;
        }
      }
    }
  }
}
